var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",staticClass:"add-form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return (() => {}).apply(null, arguments)}}},[_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":`${_vm.$t('AUCTIONS.NAME')} (*)`,"placeholder":_vm.$t('AUCTIONS.NAME')},on:{"change":_vm.onFormChanged},model:{value:(_vm.auction.name),callback:function ($$v) {_vm.$set(_vm.auction, "name", $$v)},expression:"auction.name"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.name}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":`${_vm.$t('AUCTIONS.AUCTION_TYPE')} (*)`,"placeholder":_vm.$t('AUCTIONS.AUCTION_TYPE')}},[_c('el-select',{staticClass:"select-primary pagination-select",attrs:{"clearable":"","placeholder":_vm.$t('AUCTIONS.AUCTION_TYPE')},on:{"change":_vm.onFormChanged},model:{value:(_vm.auction.auction_type),callback:function ($$v) {_vm.$set(_vm.auction, "auction_type", $$v)},expression:"auction.auction_type"}},_vm._l((_vm.AUCTION_TYPES),function(item){return _c('el-option',{key:item,staticClass:"select-primary",attrs:{"label":_vm.$t(`AUCTIONS.AUCTION_TYPE_${item}`),"value":item}})}),1)],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.auction_type}})],1),(
      [_vm.AUCTION_TYPE_TIMED, _vm.AUCTION_TYPE_EVALUATION].includes(
        _vm.auction.auction_type
      )
    )?_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('AUCTIONS.DELAY_BEFORE_END_TO_BET_AGAIN')} (*)`,"type":"number","min":"0","placeholder":`${_vm.$t('AUCTIONS.DELAY_BEFORE_END_TO_BET_AGAIN')}`},on:{"change":_vm.onFormChanged},model:{value:(_vm.auction.delay_before_end_to_bet_again),callback:function ($$v) {_vm.$set(_vm.auction, "delay_before_end_to_bet_again", $$v)},expression:"auction.delay_before_end_to_bet_again"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.delay_before_end_to_bet_again}})],1):_vm._e(),(
      [_vm.AUCTION_TYPE_TIMED, _vm.AUCTION_TYPE_EVALUATION].includes(
        _vm.auction.auction_type
      )
    )?_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('AUCTIONS.EXTENSION_TIME')} (*)`,"type":"number","min":"0","placeholder":`${_vm.$t('AUCTIONS.EXTENSION_TIME')}`},on:{"change":_vm.onFormChanged},model:{value:(_vm.auction.extension_time),callback:function ($$v) {_vm.$set(_vm.auction, "extension_time", $$v)},expression:"auction.extension_time"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.extension_time}})],1):_vm._e(),(
      [_vm.AUCTION_TYPE_TIMED, _vm.AUCTION_TYPE_EVALUATION].includes(
        _vm.auction.auction_type
      )
    )?_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('AUCTIONS.DELAY_BEFORE_ENTRY_INTO_AUCTION')} (*)`,"type":"number","min":"0","placeholder":`${_vm.$t('AUCTIONS.DELAY_BEFORE_ENTRY_INTO_AUCTION')}`},on:{"change":_vm.onFormChanged},model:{value:(_vm.auction.delay_before_entry_into_auction),callback:function ($$v) {_vm.$set(_vm.auction, "delay_before_entry_into_auction", $$v)},expression:"auction.delay_before_entry_into_auction"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.delay_before_entry_into_auction}})],1):_vm._e(),_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":`${_vm.$t('AUCTIONS.AUTHORIZED_PAYMENTS')} (*)`,"placeholder":_vm.$t('AUCTIONS.AUTHORIZED_PAYMENTS')}},[_c('el-select',{staticClass:"select-primary pagination-select",attrs:{"multiple":true,"clearable":true,"placeholder":_vm.$t('AUCTIONS.AUTHORIZED_PAYMENTS')},on:{"change":_vm.onFormChanged},model:{value:(_vm.auction.authorized_payments),callback:function ($$v) {_vm.$set(_vm.auction, "authorized_payments", $$v)},expression:"auction.authorized_payments"}},_vm._l((_vm.AUCTION_PAYMENTS),function(item,key){return _c('el-option',{key:key,staticClass:"select-primary",attrs:{"label":_vm.$t(`AUCTIONS.PAYMENT_SOURCE_${item}`),"value":item}})}),1)],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.authorized_payments}})],1),_c('div',{staticClass:"submit-wrapper"},[_c('base-button',{staticClass:"btn btn-sm elite-submit",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.auction.id ? _vm.$t("AUCTIONS.EDIT_AUCTION") : _vm.$t("AUCTIONS.ADD_AUCTION"))+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }