<template>
  <div class="elite-tabs-wrapper-content">
    <div class="row property-tab-title">
      <div class="text-left">
        <h3 class="mb-4">
          {{ `${auction.name}` }} - {{ $t("COMMON.EVENTS") }}
        </h3>
      </div>
    </div>

    <event-list-table
      :isSelectable="true"
      :filter-auction-id="auction.id"
      ref="propertyList"
    />
  </div>
</template>


<script>
import EventListTable from "@/views/Pages/AdminModule/EventManagement/partials/EventListTable.vue";

export default {
  name: "auction-view-events",

  components: {
    EventListTable,
  },

  props: ["auction"],

  mixins: [],

  data() {
    return {};
  },

  computed: {},

  methods: {},

  mounted() {},

  watch: {
    user(user) {},
  },
};
</script>
