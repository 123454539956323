<template>
  <div class="elite-tabs-wrapper-content">
    <h3 class="mb-4">{{ `${auction.name}` }}</h3>

    <div class="row mb-5">
      <div class="col-12">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("AUCTIONS.NAME") }}</dt>
          <dd class="col-sm-8">
            {{ auction.name }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("AUCTIONS.AUCTION_TYPE") }}</dt>
          <dd class="col-sm-8">
            <span>
              {{ $t(`AUCTIONS.AUCTION_TYPE_${auction.auction_type}`) }}
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("AUCTIONS.DELAY_BEFORE_END_TO_BET_AGAIN") }}
          </dt>
          <dd class="col-sm-8">
            <span>
              {{
                auction.delay_before_end_to_bet_again
                  ? `${auction.delay_before_end_to_bet_again} s`
                  : "-"
              }}
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("AUCTIONS.EXTENSION_TIME") }}
          </dt>
          <dd class="col-sm-8">
            <span>
              {{ auction.extension_time ? `${auction.extension_time} s` : "-" }}
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("AUCTIONS.DELAY_BEFORE_ENTRY_INTO_AUCTION") }}
          </dt>
          <dd class="col-sm-8">
            <span>
              {{
                auction.delay_before_entry_into_auction
                  ? `${auction.delay_before_entry_into_auction} s`
                  : "-"
              }}
            </span>
          </dd>
        </dl>

        <!-- <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.START_AT") }}</dt>
          <dd class="col-sm-8">
            <span>
              {{
                auction.start_at ? $formatDate(auction.start_at, "LLL") : "-"
              }}
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.END_AT") }}</dt>
          <dd class="col-sm-8">
            <span>
              {{ auction.end_at ? $formatDate(auction.end_at, "LLL") : "-" }}
            </span>
          </dd>
        </dl> -->

        <dl class="row">
          <dt class="col-sm-4">{{ $t("AUCTIONS.AUTHORIZED_PAYMENTS") }}</dt>
          <dd class="col-sm-8">
            <span v-for="(item, key) in auction.authorized_payments" :key="key">
              {{ $t(`AUCTIONS.PAYMENT_SOURCE_${item}`) }}
              {{ auction.authorized_payments.length - 1 > key ? "," : " " }}
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $formatDate(auction.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $formatDate(auction.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import Users from "@/components/Users.vue";
import Contacts from "@/components/Contacts.vue";

export default {
  name: "auction-view-global",

  props: ["auction"],

  components: {
    Users,
    Contacts,
  },

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {
    auctionUpdated() {
      this.$emit("auctionUpdated", true);
    },
  },

  mounted() {},

  watch: {
    auction(auction) {},
  },
};
</script>
