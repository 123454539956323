import {
  OBJECT_TYPE_PROPERTY,
  AUCTION_TYPE_TIMED,
} from "@/constants/auctions";

export default {
  type: "auctions",
  name: "",
  auction_type: AUCTION_TYPE_TIMED,
  delay_before_end_to_bet_again: 0,
  extension_time: null,
  delay_before_entry_into_auction: 0,
  authorized_payments: [],
};
